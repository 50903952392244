import '../ui-anchor/ui-anchor.et.js';
import '../ui-annotation/ui-annotation.et.js';
import '../ui-assets/ui-assets.et.js';
import '../ui-autocomplete/ui-autocomplete.et.js';
import '../ui-breadcrumbs/ui-breadcrumbs.et.js';
import '../ui-calendar/ui-calendar.et.js';
import '../ui-chip/ui-chip.et.js';
import '../ui-comparison-cards/ui-comparison-cards.et.js';
import '../ui-cookie-consent/ui-cookie-consent.et.js';
import '../ui-countdown/ui-countdown.et.js';
import '../ui-curtain/ui-curtain.et.js';
import '../ui-datepicker/ui-datepicker.et.js';
import '../ui-daterange/ui-daterange.et.js';
import '../ui-dialogbubble/ui-dialogbubble.et.js';
import '../ui-dropdown/ui-dropdown.et.js';
import '../ui-feedback/ui-feedback.et.js';
import '../ui-form/ui-form.et.js';
import '../ui-message/ui-message.et.js';
import '../ui-modal/ui-modal.et.js';
import '../ui-monthpicker/ui-monthpicker.et.js';
import '../ui-nav/ui-nav.et.js';
import '../ui-navitem/ui-navitem.et.js';
import '../ui-navlist/ui-navlist.et.js';
import '../ui-navtoggle/ui-navtoggle.et.js';
import '../ui-offer/ui-offer.et.js';
import '../ui-pagination/ui-pagination.et.js';
import '../ui-phonefield/ui-phonefield.et.js';
import '../ui-searchinput/ui-searchinput.et.js';
import '../ui-signing/ui-signing.et.js';
import '../ui-slider/ui-slider.et.js';
import '../ui-slides/ui-slides.et.js';
import '../ui-stepper/ui-stepper.et.js';
import '../ui-table-cloneable/ui-table-cloneable.et.js';
import '../ui-table-expandable/ui-table-expandable.et.js';
import '../ui-table-sortable/ui-table-sortable.et.js';
import '../ui-tabs/ui-tabs.et.js';
import '../ui-textcounter/ui-textcounter.et.js';
import '../ui-timerange/ui-timerange.et.js';
import '../ui-tooltip/ui-tooltip.et.js';
import '../ui-upload-item/ui-upload-item.et.js';
import '../ui-upload/ui-upload.et.js';
import '../ui-video/ui-video.et.js';
import '../ui-wizard/ui-wizard.et.js';
import { Labels } from '../../shared-components/global/labels.js';

Labels.LANGUAGE = 'et';

window.UI = { ...window.UI, Labels };
